/*===== GOOGLE FONTS =====*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;600;700;800;900&display=swap');
@import url("https://cdn.jsdelivr.net/gh/devicons/devicon@master/devicon.min.css");
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.dev-icons {
    i {
        font-size: 40px;
        padding: 17px 12px;
    }
}
/*===== VARIABLES CSS =====*/
:root{
    --header-height: 3rem;
    --font-semi: 600;
}

/*===== Colores =====*/
:root{
    --first-color: #4070F4;
    --second-color: #0E2431;
}

/*===== Fuente y tipografia =====*/
:root{
    --body-font: 'Poppins', sans-serif;

    --big-font-size: 2rem;
    --h2-font-size: 1.25rem;
    --normal-font-size: 0.938rem;

    @media screen and (min-width: 768px){
        --big-font-size: 3.5rem;
        --h2-font-size: 2rem;
        --normal-font-size: 1rem;
    }
}

/*===== Margenes =====*/
:root{
    --mb-1: 0.5rem;
    --mb-2: 1rem;
    --mb-3: 1.5rem;
    --mb-4: 2rem;
    --mb-5: 2.5rem;
    --mb-6: 3rem;
}

/*===== z index =====*/
:root{
    --z-back: -10;
    --z-normal: 1;
    --z-tooltip: 10;
    --z-fixed: 100;
}
.hide {
    display: none!important;
}
/*===== BASE =====*/
*,::before,::after{
    box-sizing: border-box;
}
html{
    scroll-behavior: smooth;
}
body{
    margin: var(--header-height) 0 0 0;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    color: var(--second-color);
}
h1,h2,p{
    margin: 0;  
}
ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
a{
    text-decoration: none;
}
img{
    max-width: 100%;
    height: auto;
    display: block; // borra los espacios en blanco
}

@media screen and (max-width: 768px) {
  .home__img{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 295px;
  }
}

/*===== CLASS CSS ===== */
.section-title{
    position: relative;
    font-size: var(--h2-font-size);
    color: var(--first-color);
    margin-top: var(--mb-2);
    margin-bottom: var(--mb-4);
    text-align: center;

    &::after{
        position: absolute;
        content: '';
        width: 64px;
        height: 0.18rem;
        left: 0;
        right: 0;
        margin: auto;
        top: 2rem;
        background-color: var(--first-color);
    }
}
.section{
    padding-top: 3rem;
    padding-bottom: 2rem;
}

/*===== LAYOUT =====*/
.bd-grid{
    max-width: 1124px;
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 2rem;
    width: calc(100% - 2rem);
    margin-left: var(--mb-2);
    margin-right: var(--mb-2);
}
.l-header{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(146,161,176,.15);
}
h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700!important;
    text-align: left;
}

/*===== NAV =====*/
.button {
    &:hover {
        color: #FFF;
        text-decoration: none;
    }
}
.nav{
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: var(--font-semi);

    &__menu{
        @media screen and (max-width: 768px){
            position: fixed;
            display: inline-table;
            top: var(--header-height);
            right: -100%;
            width: 100%;
            height: 100%;
            padding: 2rem;
            background-color: var(--second-color);
            transition: .5s;
        }
    }
    &__item{
        margin-bottom: var(--mb-4);
    }
    &__item:last-child {
        margin-bottom: var(--mb-0);
    }
    &__link{
        position: relative;
        color: #fff;

        &:hover{
            position: relative;
            text-decoration: none;

            &::after{
                position: absolute;
                content: '';
                width: 100%;
                height: 0.18rem;
                left: 0;
                top: 2rem;
                background-color: var(--first-color);
            }
        }
    }
    &__logo{
        color: var(--second-color);
    }
    &__toggle{
        color: var(--second-color);
        font-size: 1.5rem;
        cursor: pointer;
    }
    &__list {
        margin-bottom: var(--mb-0);
    }
}
/*Active menu*/
.active::after{
    position: absolute;
    content: '';
    width: 100%;
    height: 0.18rem;
    left: 0;
    top: 2rem;
    background-color: var(--first-color);
}

/*=== Show menu ===*/
.show{
    right: 0;
}

/*===== HOME =====*/
.home{
    height: calc(100vh - 3rem);
    row-gap: 1rem;

    &__data{
        align-self: center;
    }
    &__title{
        font-size: var(--big-font-size);
        margin-bottom: var(--mb-5);

        &-color{
            color: var(--first-color);
        }
    }
    &__social{
        position: absolute;
        bottom: -5px;
        left: 12px;
        display: flex;
        flex-direction: column;
        &-icon{
            width: max-content;
            margin-bottom: var(--mb-2);
            font-size: 1.5rem;
            color: var(--second-color);

            &:hover{
                color: var(--first-color);
            }
        }
    }

    &__img{

    }
}

/*BUTTONS*/
.button{
    display: inline-block;
    background-color: var(--first-color);
    color: #fff;
    padding: .75rem 2.5rem;
    font-weight: var(--font-semi);
    border-radius: .5rem; 

    &:hover{
        box-shadow: 0px 10px 36px rgba(0,0,0,.15);
    }
}

/* ===== ABOUT =====*/
.about{
    &__container{
        row-gap: 2rem;
        text-align: center;
    }
    &__subtitle{
        margin-bottom: var(--mb-2);
    }

    &__img{
        justify-self: center;
        
        & img{
            width: 200px;
            border-radius: .5rem;
        }
    }
}

/* ===== SKILLS =====*/
.skills{
    &__container{
        row-gap: 2rem;
        text-align: center;
    }
    &__subtitle{
        margin-bottom: var(--mb-2);
    }
    &__text{
        margin-bottom: var(--mb-4);
    }
    &__data{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        font-weight: var(--font-semi);
        padding: .5rem 1rem;
        margin-bottom: var(--mb-4);
        border-radius: .5rem;
        box-shadow: 0px 4px 25px rgba(14, 36, 49, 0.15);
    }
    &__icon{
        font-size: 2rem;
        margin-right: var(--mb-2);
        color: var(--first-color);
    }
    &__names{
        display: flex;
        align-items: center;
    }
    &__bar{
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: var(--first-color);
        height: .25rem;
        border-radius: .5rem;
        z-index: var(--z-back);
    }
    &__html{
        width: 95%;
    }
    &__css{
        width: 85%;
    }
    &__js{
        width: 65%;
    }
    &__ux{
        width: 85%;
    }
    &__img{
        border-radius: .5rem;
    }
}
/* ===== WORK =====*/
.work{
    &__container{
        row-gap: 2rem;
    }
    &__img{
        box-shadow: 0px 4px 25px rgba(14, 36, 49, 0.15); 
        border-radius: .5rem;
        overflow: hidden;
        
        & img{
            transition: 1s;
            cursor: pointer;

            &:hover{
                transform: scale(1.1);
            }
        }
    }
}

/* ===== CONTACT =====*/
.contact{
    &__input{
        width: 100%;
        font-size: var(--normal-font-size);
        font-weight: var(--font-semi);
        padding: 1rem;
        border-radius: .5rem;
        border: 1.5px solid var(--second-color);
        outline: none;
        margin-bottom: var(--mb-4);
    }
    &__button{
        display: block;
        border: none;
        outline: none;
        font-size: var(--normal-font-size);
        cursor: pointer;
        margin-left: auto;
    }
}

/* ===== FOOTER =====*/
.footer{
    background-color: var(--second-color);
    color: #fff;
    text-align: center;
    font-weight: var(--font-semi);
    padding: 2rem 0;

    &__title{
        font-size: 2rem;
        margin-bottom: var(--mb-4);
    }
    &__social{
        margin-bottom: var(--mb-4);
    }
    &__icon{
        font-size: 1.5rem;
        color: #fff;
        margin: 0 var(--mb-2);
    }
}

/* ===== MEDIA QUERIES=====*/
@media screen and(min-width: 768px){
    body{
        margin: 0;
    }
    .section{
        padding-top: 4rem;
        padding-bottom: 3rem;
    }

    .section-title{
        margin-bottom: var(--mb-6);

        &::after{
            width: 80px;
            top: 3rem;
        }
    }

    .nav{
        height: calc(var(--header-height) + 1rem);
        &__list{
            display: flex;
            padding-top: 0;
        }
        &__item{
            margin-left: var(--mb-6);
            margin-bottom: 0;
        }
        &__toggle{
            display: none;
        }
        &__link{
            color: var(--second-color);
        }
    }
    .home{
        height: 100vh;
        &__data{
            align-self: flex-end;
        }
        &__social{
            bottom: -35px;
            padding-top: 0;
            padding-bottom: 2.5rem;
            flex-direction: row;
            align-self: flex-end;

            &-icon{
                margin-bottom: 0;
                margin-right: var(--mb-4);
            }
        }
        &__img{
            width: 357px;
            bottom: 15%;
        }
    }

    .about{
        &__container{
            grid-template-columns: repeat(2,1fr);
            align-items: center;
            text-align: initial;
        }
        &__img{
            & img{
                width: 300px;
            }
        }
    }
    .skills{
        &__container{
            grid-template-columns: repeat(2,1fr);
            align-items: center;
            text-align: initial;
        }
    }
    .work{
        &__container{
            grid-template-columns: repeat(3,1fr);
            grid-template-rows: repeat(2, 1fr);
            column-gap: 2rem;
        }
    }
    .contact{
        &__form{
            width: 360px;
        }
        &__container{
            justify-items: center;
        }
    }

}
@media screen and(min-width: 1024px){
    .bd-grid{
        margin-left: auto;
        margin-right: auto;
    }
    .home{
        &__img{
            right: 10%;
        }
    }
    .home__img{
        width: 457px;
        bottom: 15%;
    }
}

.intro-wrapper{
    position: relative;
	background-color: var(--secondaryColor);
	border:1px solid var(--borderColor);
	border-radius:5px 5px 0 0;


	-webkit-box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.22);;
	-moz-box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.22);;
	box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.22);;

    // display: grid;
    margin-top: 0.9999rem!important;
	grid-template-columns: 1fr 1fr;
	grid-template-areas: 
		'nav-wrapper nav-wrapper'
		'left-column right-column'
	;
}
@media screen and (min-width: 768px) {
    .intro-wrapper {
        margin-top: 3rem;
    }
}
.nav-wrapper {
    background-color: #4070f4;
    min-height: 50px;
}
.dots-wrapper{
	display: flex;
    padding: 10px;
}

#dot-1{
	background-color:  #FC6058;
}

#dot-2{
	background-color:  #FEC02F;
}

#dot-3{
	background-color:  #2ACA3E;
}

.browser-dot{
	background-color: black;
	height: 15px;
	width: 15px;
	border-radius: 50%;
	margin: 5px;

	-webkit-box-shadow: -1px 1px 3px -1px rgba(0,0,0,0.75);
	-moz-box-shadow: -1px 1px 3px -1px rgba(0,0,0,0.75);
	box-shadow: -1px 1px 3px -1px rgba(0,0,0,0.75);

}
#theme-options-wrapper{
	display: flex;
	justify-content: center;
}

.theme-dot{
	height: 30px;
	width: 30px;
	background-color: black;
	border-radius: 50%;

	margin: 5px;
	border:2px solid var(--themeDotBorder);

	-webkit-box-shadow: -1px 1px 3px -1px rgba(0,0,0,0.75);
	-moz-box-shadow: -1px 1px 3px -1px rgba(0,0,0,0.75);
	box-shadow: -1px 1px 3px -1px rgba(0,0,0,0.75);

	cursor: pointer;
}


.theme-dot:hover{
	border-width: 5px;
}
.contact__form {
    .contact__input {
        margin-bottom: 0!important;
    }
    .spacer {
        height: 22px;
    }
}
span {
    &.errors {
        color: red;
        padding: 0;
        margin: 0;
        text-align: left;
        align-self: flex-start;
        display: flex;
    }
}
.on-success {
    p {
        color: #2ACA3E;
        padding: 12px 12px;
    }
}